@tailwind base;
@tailwind components;
@tailwind utilities;


/* @font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter-Regular.ttf') format('truetype');
} */

@font-face {
    font-family: 'Inter';
    font-weight: 300;
    font-style: normal;
    src: url('./assets/fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 400;
    font-style: normal;
    src: url('./assets/fonts/Inter-Medium.ttf') format('truetype');
}
  
@font-face {
    font-family: 'Inter';
    font-weight: 500;
    font-style: normal;
    src: url('./assets/fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 600;
    font-style: normal;
    src: url('./assets/fonts/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 700;
    font-style: normal;
    src: url('./assets/fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Cerebri Sans';
    font-weight: 400;
    font-style: normal;
    src: url('./assets/fonts/cerebrisans/CerebriSans-Regular.eot');
    src: url('./assets/fonts/cerebrisans/CerebriSans-Regular.eot?#iefix') format("embedded-opentype"), url('./assets/fonts/cerebrisans/CerebriSans-Regular.woff') format("woff"), url('./assets/fonts/cerebrisans/CerebriSans-Regular.ttf') format("truetype")
  }
  
  @font-face {
    font-family: 'Cerebri Sans';
    font-weight: 500;
    font-style: normal;
    src: url('./assets/fonts/cerebrisans/CerebriSans-Medium.eot');
    src: url('./assets/fonts/cerebrisans/CerebriSans-Medium.eot?#iefix') format("embedded-opentype"), url('./assets/fonts/cerebrisans/CerebriSans-Medium.woff') format("woff"), url('./assets/fonts/cerebrisans/CerebriSans-Medium.ttf') format("truetype")
  }
  
  @font-face {
    font-family: 'Cerebri Sans';
    font-weight: 600;
    font-style: normal;
    src: url('./assets/fonts/cerebrisans/CerebriSans-SemiBold.eot');
    src: url('./assets/fonts/cerebrisans/CerebriSans-SemiBold.eot?#iefix') format("embedded-opentype"), url('./') format("woff"), url('./assets/fonts/cerebrisans/CerebriSans-SemiBold.ttf') format("truetype")
  }

  @font-face {
    font-family: 'Cerebri Sans';
    font-weight: 700;
    font-style: normal;
    src: url('./assets/fonts/cerebrisans/CerebriSans-Bold.eot');
    src: url('./assets/fonts/cerebrisans/CerebriSans-Bold.eot?#iefix') format("embedded-opentype"), url('./') format("woff"), url('./assets/fonts/cerebrisans/CerebriSans-Bold.ttf') format("truetype")
  }

@layer base {
    body{
        /* @apply overflow-hidden */
    }
    html{
        @apply font-inter
    }
    .card{
        @apply border border-gray-100  divide-y divide-gray-100 bg-white rounded-md
    }
    .card-header{
        @apply border-b-gray-100 px-2 py-[10px]
    }
    .card-body{
        @apply px-2 py-[12px]
    }
    .card-title{
        @apply font-semibold
    }
    .search-input{
        @apply bg-gray-100 h-8 px-2 rounded-md w-full transition-all duration-700 focus:flex-grow focus:outline-none focus:ring-0 outline-none ring-0
    }
    .nav-link{
        @apply pl-edge-space py-[12px] inline-block  hover:bg-prim-green w-full opacity-90 pr-2 
    }
    .nav-link.active{
        @apply bg-prim-yellow border-l-[5px] border-l-prim-color opacity-100 pl-[42px]
    }
    .nav-link .fa{
        @apply opacity-80 mr-2 mt-[5px]
    }
    .sub-nav-link{
        @apply pl-edge-space py-[9px] text-sm font-normal inline-block  hover:bg-prim-green w-full opacity-90
    }
    .sub-nav-link.active{
        @apply bg-prim-yellow border-l-[5px] border-l-prim-color opacity-100 pl-[43px]
    }

    .form-input-1{
        @apply bg-white bg-opacity-20 text-white text-[13px] rounded block w-full p-1  focus:outline-none text-opacity-80
    }
    .form-label-1{
        @apply block mb-1 ml-[2px] text-sm  text-white  opacity-80
    }
    .form-input-1-icon{
        @apply w-[17px] h-[17px] text-white text-opacity-60
    }
    tbody td{
        @apply px-3 py-4 text-gray-500  text-sm
    }
    tbody tr{
        @apply bg-white border-b 
    }
    thead{
        @apply text-gray-700  bg-gray-50 text-sm
    }

    thead th{
        @apply px-3 py-3 font-normal 
    }
    .modal{
        @apply fixed z-[2] pt-10 left-0 top-0 w-full h-full overflow-auto bg-black bg-opacity-30 flex justify-center 
    }
    .modal-content{
        @apply h-fit bg-white rounded shadow p-4
    }
    .modal-md{
        @apply sm:w-2/3 md:w-2/3 lg:w-2/3 xl:w-1/3 2xl:w-1/3 3xl:w-1/3
    }
    .modal-lg{
        @apply sm:w-3/4 md:w-3/4 lg:w-3/4 xl:w-2/4 2xl:w-2/4 3xl:w-2/4
    }

    .form-input-2{
        @apply border border-gray-300 text-gray-900 text-sm rounded-md  block w-full p-2.5 focus:outline-none focus:ring-0 outline-none ring-0 disabled:bg-gray-100
    }
    .danger-btn-1{
        @apply text-white bg-red-600 hover:bg-red-800 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none disabled:bg-prim-yellow 
    }
    .prim-btn-login{
        @apply text-black bg-prim-yellow hover:bg-prim-green font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none w-full disabled:bg-prim-yellow disabled:opacity-80
    }
    .prim-btn-1{
        @apply text-black bg-prim-yellow hover:bg-prim-green focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none disabled:bg-prim-yellow 
    }
    
    .prim-btn-2{
        @apply text-black bg-prim-green hover:bg-prim-yellow focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none disabled:bg-prim-green 
    }
    .btn-light{
        @apply text-black bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center
    }

    .btn-secondary-1{
        @apply py-2.5 px-5  text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-prim-green focus:z-10 focus:ring-4 focus:ring-gray-200
    }
    .dropdown{
        @apply z-10 absolute  bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700
    }
    .dropdown-item{
        @apply block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white
    }
    
    .dropdown-content{
        @apply py-2 text-sm text-gray-700 dark:text-gray-200
    }
    .badge-success{
        @apply bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5   rounded 
    }
    .badge-failed{
        @apply bg-red-100 text-red-800 text-xs font-medium  px-2.5 py-0.5   rounded 
    }
    .badge-pending{
        @apply bg-gray-100 text-gray-800 text-xs font-medium  px-2.5 py-0.5   rounded 
    }
    .tab-inactive{
        @apply inline-block  py-2 px-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300
    }
    .tab-active{
        @apply inline-block py-2 px-4 text-prim-green border-b-2 border-prim-green rounded-t-lg active 
    }
}
.progressbar-text{
    @apply absolute  w-full flex flex-col text-center justify-center  top-0 h-full
}
